import React, { useRef, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { debounce, find, reduce, values, get } from 'lodash';
import { Row, Col, Button, Popup, Input, PopupHeader } from '@commonsku/styles';
import { createAddClient } from '../../../actions/client';
import { createAddTemp } from '../../../actions/temp';
import { createLoadCompanyClientList, } from '../../../actions/company_search';
import { oauth } from '../../../utils';
import { useForm, useAsync } from '../../../hooks';
import { getIndustryOptions, getOptions } from '../selectors';
import { Select, Alert, SearchDropdownMenu } from '../../helpers';

export function CreateClientPopup({
    industry_options,
    users,
    companies,
    onCreate,
    client_name,
    client_rep_id,
    onClosePopup,
    onClose,
    ...props
}) {
    const { form, handleChange, handleSelectChange, setInForm } = useForm({
        client_name: client_name||'',
        sales_rep_id: client_rep_id,
        industry_id: '',
    });
    const [showErrorHighlight, setShowErrorHighlight] = useState(false);
    const [clientExists, setClientExists] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [clients, setClients] = useState({
        displayIds: [],
        data: {},
    });

    const ref = useRef();
    const [rect, setRect] = useState({});

    const handleResize = useCallback(() => {
        setRect(ref && ref.current ? ref.current.getBoundingClientRect() : {});
    }, []);

    const clientNameExists = React.useMemo(
        () => get(clientExists, ['client_name'], null),
        [clientExists]
    );

    const isFormValid = React.useMemo(() => {
        return form.client_name && form.client_name.length > 0
            && form.industry_id && form.industry_id.length > 0
            && !Boolean(clientNameExists);
    }, [form, clientNameExists]);

    const renderAlert = React.useCallback(() => {
        if (clientNameExists) {
            return (
                <Alert type="danger">
                    <span style={{ fontWeight: 'bold' }}>{clientNameExists}</span> already exists!
                </Alert>
            );
        }

        if (showErrorHighlight) {
            if (isFormValid) {
                return <Alert type="success">Looks good!</Alert>;
            }
            return <Alert type="danger">Please complete all required fields!</Alert>;
        }

        return null;
    }, [clientNameExists, showErrorHighlight, isFormValid]);

    const searchClient = useCallback(
        debounce(async (clientName) => {
            const { json } = await oauth('GET', 'company', {
                company_type: 'CLIENT',
                search: true,
                include_inactive: true,
                'max-results': 10,
                company_name: clientName.toLowerCase().trim(),
            });

            setClients(
                s => {
                    return {
                        displayIds: json.companies.map(c => c.client_id),
                        data: {
                            ...s.data,
                            ...reduce(
                                json.companies,
                                (acc, c) => ({...acc, [c.client_id]: c}),
                                {}
                            ),
                        },
                    };
                }
            );

            return json.companies;
        }, 400),
        []
    );

    const renderClientsDropdown = useCallback(() => {
        if (clients.displayIds.length <= 0 || !showDropdown) {
            return null;
        }

        return (
            <SearchDropdownMenu
                onClose={() => setShowDropdown(false)}
                top={rect.top}
                left={rect.left}
                width={rect.width}
                items={clients.displayIds.map(id => ({
                    content: clients.data[id].client_name,
                    onClick: () => {
                        setInForm('client_name', clients.data[id].client_name);
                        setShowDropdown(false);
                    }
                }))} />
        );
    }, [clients, showDropdown, rect.left, rect.top, rect.width, setInForm]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
          onClose && onClose();
        };
    }, [onClose, handleResize]);

    useEffect(() => {
        setClientExists(false);
        if (!form.client_name) {
            return;
        }

        searchClient(form.client_name);
    }, [form.client_name, searchClient]);

    useEffect(() => {
        const clientsList = values(clients.data);
        if (!clientsList.length) { return; }
        const found = find(
            clientsList,
            v => v.client_name.toLowerCase().trim() === form.client_name.toLowerCase().trim()
        );
        setClientExists(found);
        setShowDropdown(!Boolean(found));
    }, [form.client_name, clients]);

    const handleSubmit = () => {
        if (!isFormValid) {
            setShowErrorHighlight(true);
            return false;
        }
        const new_client = onCreate(form.client_name, form.industry_id, form.sales_rep_id);
        new_client.then(res => {
            if (res && res.payload && res.payload.value) {
                const client_id = res.payload.value;
                window.open(`/client.php?id=${client_id}`, '_blank');
            }
        });

        onClosePopup();
    };

    return (
        <Popup mdStyle="width: 530px;"
            style={{height: 'fit-content', minHeight: 200}}
            header={
                <PopupHeader xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                  <Col xs sm={5} style={{textAlign: 'left', alignSelf: 'center'}}>
                      <span className="title">New Client</span>
                  </Col>
                  <Col xs sm={7} style={{textAlign: 'right', alignSelf: 'center'}}>
                    <Button style={{ marginRight: 5 }} onClick={onClosePopup}>Close</Button>
                    <Button cta onClick={handleSubmit}>Create</Button>
                  </Col>
              </PopupHeader>
            }
            onClose={() => onClosePopup()}
        >
            <Row>
                {renderAlert()}
                <Col padded xs md={8} mdOffset={2} ref={ref}>
                    <Input required
                        name="client_name"
                        value={form.client_name}
                        error={showErrorHighlight && (form.client_name === '' || form.client_name === null)}
                        placeholder="Client Name"
                        onChange={e => {
                            const value = e.target.value ? `${e.target.value}`.trimStart() : '';
                            if (value.length > 250) {
                                return;
                            }
                            setInForm('client_name', value);
                        }}
                    />
                    {renderClientsDropdown()}
                </Col>
            </Row>
            <Row>
                <Col padded xs md={8} mdOffset={2}>
                    <Select required
                        name="industry_id"
                        value={form.industry_id}
                        error={showErrorHighlight && (form.industry_id === '' || form.industry_id === null)}
                        options={industry_options}
                        onChange={handleSelectChange('industry_id')}
                        inPopup
                    />
                </Col>
            </Row>
            <Row style={{paddingBottom: 20,}}><br /></Row>
        </Popup>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        industry_options: getIndustryOptions(state),
        companies: getOptions(Object.values((state.entities.company_search || {}).companies || {}), 'client_name', 'client_name'),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onCreate: (client_name, industry_id, sales_rep_id) => dispatch(createAddClient(client_name, industry_id, sales_rep_id)).then(
        action => dispatch(createAddTemp('NEW_CLIENT', action.payload.client.client_id))
    ),
    loadCompanyClients: (filters) => {
        dispatch(createLoadCompanyClientList(filters));
    },
});

const ConnectedCreateClientPopup = connect(mapStateToProps, mapDispatchToProps)(CreateClientPopup);
export default ConnectedCreateClientPopup;
