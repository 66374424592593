import React, { useState, useMemo } from 'react';
import { Row, Col, Dropzoned, Link, Button, Popup, Input, PopupHeader, Table, TBody, TD, TR, Text, Theme, H1, Label } from '@commonsku/styles';
import { useForm } from '../../../hooks';
import { Select } from '../../helpers';
import { uploadArtifact } from '../../../utils';

const ERRORS = {
  'MISSING-PARAM': ({field}) => `Missing required parameter '${field}'`,
  'FORBIDDEN': () => `Missing client import permissions`,
  'SYNC-IN-PROGRESS': ({platform}) => `A ${platform} sync is currently in progress, please wait for it to finish before importing clients`,
  'EXTRA-COLUMNS': () => 'Invalid template: the provided CSV file contains invalid headers',
  'MISSING-COLUMN': ({column}) => `Invalid template: the provided CSV file is missing header '${column}'`,
  'COMMA-IN-TAG': () => 'Client tags cannot contain commas, please choose another default tag',
  'EMPTY-FILE': () => 'The provided CSV file is empty',
  'DB-ERROR': () => 'An unknown error occurred',
}

const translateError = (error) => {
  const key = error.error;
  if (!(key in ERRORS)) {
      return 'An unknown error occurred';
  }
  return ERRORS[key](error);
}

function FileUpload({ file, setFile }) {
  const placeholder = <div style={{display: 'flex'}}>
    <svg style={{marginLeft: '1rem'}} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38 30V38H5.99996V30H0.666626V38C0.666626 40.9333 3.06663 43.3333 5.99996 43.3333H38C40.9333 43.3333 43.3333 40.9333 43.3333 38V30H38ZM8.66663 14L12.4266 17.76L19.3333 10.88V32.6667H24.6666V10.88L31.5733 17.76L35.3333 14L22 0.666656L8.66663 14Z" fill="#B8C4CB"/>
    </svg>
    <div style={{flexGrow: 1, textAlign: 'center', alignSelf: 'center', color: '#899CA9'}}>
      {file 
        ? <Text style={{color: 'inherit'}}>{file.name}</Text>
        : <Text style={{color: 'inherit'}}>Drag and drop the CSV file here or <Link href="#">Browse Files</Link></Text>
      }
    </div>
  </div>;

  return <Dropzoned
    style={{ padding: '0px' }}
    placeholder={placeholder} 
    accept=".csv,text/csv"
    onDrop={([file]) => setFile(file)} 
  />;
}

function ClientImportPopup({
    sales_rep_options,
    department_options,
    industry_options,
    terms_options,
    tax_options,
    onClosePopup,
    setPreview,
}) {
    const [showErrorHighlight, setShowErrorHighlight] = useState(false);
    const [file, setFile] = useState(false);
    const [errors, setErrors] = useState(false);

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    const date = `${mm}-${dd}-${yyyy}`;

    const { form, handleSelectChange, handleChange } = useForm({
        default_sales_rep: '',
        default_department: '',
        default_industry: '',
        default_terms: '',
        default_tax: '',
        default_tag: '',
    });

    const isFormValid = useMemo(() => {
        return form.default_sales_rep && form.default_sales_rep.length > 0
            && form.default_department && form.default_department.length > 0
            && form.default_industry && form.default_industry.length > 0
            && form.default_terms && form.default_terms.length > 0
            && form.default_tax && form.default_tax.length > 0
            && file
    }, [form, file]);

    const handleSubmit = () => {
          if (!isFormValid) {
              setShowErrorHighlight(true);
              return false;
          }

        const client_import_params = {
            import_type: 'CLIENT',
            default_sales_rep: form.default_sales_rep,
            default_department: form.default_department,
            default_industry: form.default_industry,
            default_terms: form.default_terms,
            default_tax: form.default_tax,
            default_tag: form.default_tag,
            preview: true,
        };

        uploadArtifact('import', file, client_import_params)
            .then(({ json }) => {
              if (json && json.error) {
                setErrors(json.error.errors.map(translateError));
                return;
              }
              setPreview(json.report.preview, json.bulk_import_id);
              onClosePopup();
            },
	    ({ json }) => {
              if (json && json.errors) {
                setErrors(json.errors.map(translateError));
                return;
              }
              setErrors(['An unknown error occurred']);
	    })
            .catch(() => setErrors(['An unknown error occurred']));
    };

    return <Theme>
        <Popup
            style={{height: 'fit-content', minHeight: '200px', maxHeight: '1000px', width: '542px', padding: '32px'}}
            header={
              <PopupHeader style={{padding: '0px'}}>
                <H1 style={{fontSize: '32px', marginTop: '0px'}}>
                  Import Clients & Contacts
                </H1>
              </PopupHeader>
            }
            onClose={() => onClosePopup()}
        >
          <div style={{overflowX: 'hidden'}}>
            <Row>
              <Col xs>
                <Label>Default Sales Rep</Label>
              </Col>
              <Col xs>
                <Select required
                  name="default_sales_rep"
                  value={form.default_sales_rep}
                  error={showErrorHighlight && (form.default_sales_rep === '' || form.default_sales_rep === null)}
                  options={sales_rep_options}
                  placeholder="Select Rep"
                  onChange={handleSelectChange('default_sales_rep')}
                  inPopup
                />
              </Col>
              <Col xs>
                <Label>Default Department</Label>
              </Col>
              <Col xs>
                <Select required
                  name="default_department"
                  value={form.default_department}
                  error={showErrorHighlight && (form.default_department === '' || form.default_department === null)}
                  options={department_options}
                  placeholder="Select Department"
                  onChange={handleSelectChange('default_department')}
                  inPopup
                />
              </Col>
              <Col xs>
                <Label>Default Industry</Label>
              </Col>
              <Col xs>
                <Select required
                  name="default_industry"
                  value={form.default_industry}
                  error={showErrorHighlight && (form.default_industry === '' || form.default_industry === null)}
                  options={industry_options}
                  placeholder="Select Industry"
                  onChange={handleSelectChange('default_industry')}
                  inPopup
                />
              </Col>
              <Col xs>
                <Label>Default Terms</Label>
              </Col>
              <Col xs>
                <Select required
                  name="default_terms"
                  value={form.default_terms}
                  error={showErrorHighlight && (form.default_terms === '' || form.default_terms === null)}
                  options={terms_options}
                  placeholder="Select Terms"
                  onChange={handleSelectChange('default_terms')}
                  inPopup
                />
              </Col>
              <Col xs>
                <Label>Default Tax</Label>
              </Col>
              <Col xs>
                <Select required
                  name="default_tax"
                  value={form.default_tax}
                  error={showErrorHighlight && (form.default_tax === '' || form.default_tax === null)}
                  options={tax_options}
                  placeholder="Select Tax"
                  onChange={handleSelectChange('default_tax')}
                  inPopup
                />
              </Col>
              <Col xs>
                <Label>
                  Default Tag <Text style={{fontFamily: 'skufont-regular'}}>(optional)</Text>
                </Label>
              </Col>
              <Col xs>
                <Input required
                  name="default_tag"
                  value={form.default_tag}
                  placeholder={`Imported ${date}`}
                  onChange={handleChange}
                  inPopup
                />
              </Col>
              <Col xs className="resku" style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#00B1C8"/>
                </svg>
                <Link
                  style={{marginLeft: '0.5rem'}} 
                  as="a"
                  href="https://help.commonsku.com/knowledge/how-do-i-import-my-clients-into-commonsku"
                  target="_blank" 
                  rel="noreferrer"
                >
                  Learn how to prepare the CSV file
                </Link>
              </Col>
              <Col xs style={{marginBottom: '1rem'}}>
                <FileUpload file={file} setFile={setFile} />
              </Col>
              <Col xs style={{display: 'flex'}}>
                <Button secondary style={{flexGrow: '1', marginRight: '1rem'}} onClick={onClosePopup}>Cancel</Button>
                <Button cta style={{width: '70%'}} disabled={!isFormValid} onClick={handleSubmit}>Preview</Button>
              </Col>
              {errors && <Col xs style={{marginTop: '1rem'}}>
                <Table>  
                  <TBody style={{border: 'none'}}>
                    {errors.map(error => (
                      <TR>
                        <TD style={{padding: '8px', borderRadius: '8px', color: '#D10411'}} bg='#FFF2F3' colSpan='13'>
                          {error}
                        </TD>
                      </TR>
                    ))}
                </TBody>
              </Table>        
             </Col>}
            </Row>
          </div>
        </Popup>
      </Theme>;
}

export default ClientImportPopup;
