import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Badge, LabeledCheckbox,
  Input, Table, THead, THSorted, TBody, TD, TR,
} from '@commonsku/styles';
import { debounce } from 'lodash';
import { createLoadOrderListV2 } from '../../actions/order';
import { _createEventHandler } from '../../utils';
import useStateWithCallback from '../../hooks/useStateWithCallback';
import { Pagination, Select } from '../helpers';
import { ORDER_TYPE_MAP } from './utils';
import DateInput, { CSKUInput } from '../DateInput';

export function ClientProjects({
  client_id,
  orders,
  loadOrders,
}) {
  const orderTypes = Object.keys(ORDER_TYPE_MAP).map(v => ({label: ORDER_TYPE_MAP[v], value: v}));
  const initial_order_search = {
    search: '',
    order_type: 'OPPORTUNITY',
    to_date_orderdate: '',
    product: '',
    'max-results': 20,
    'start-index': 0,
    ignore_id: '',
    show_deleted: '',
  };

  useEffect(() => {
    loadOrders(client_id, order_search_filers);
  }, []);

  const [order_search_filers, setOrderSearchFilter] = useStateWithCallback(initial_order_search, (state) => {
    debounce(function () {
      loadOrders(client_id, {...state, show_deleted: state.show_deleted || ''});
    }, 300)();
  });
  const onUpdate = (data) => setOrderSearchFilter({...order_search_filers, ...data});

  function alternateOrder(order = null) {
    order = order || order_search_filers['orderby-dir'] || 'desc';
    return order === 'desc' ? 'asc' : 'desc';
  }

  function getCurrentOrder(type) {
    if (order_search_filers['orderby-field'] === type) {
      return order_search_filers['orderby-dir'] === 'desc' ? 'desc' : 'asc';
    }
    return null;
  }

  return (
    <div>
      <Row>
        <Col padded xl={6} sm={6} xs><Input placeholder="Name or #" value={order_search_filers.name}
          onChange={_createEventHandler(onUpdate, 'search', {additionalPayload:{'start-index': 0}})} /></Col>
        <Col padded xl={6} sm={6} xs>
            <DateInput
              customInput={<CSKUInput />}
              showMonthDropdown
              showYearDropdown
              value={order_search_filers.to_date_orderdate ? order_search_filers.to_date_orderdate : null}
              placeholder="Before this Date"
              onChange={(date) => {
                if (!date) {
                  onUpdate({to_date_orderdate: '', 'start-index': 0, 'orderby-dir': 'desc',});
                } else {
                  onUpdate({to_date_orderdate: date, 'start-index': 0, 'orderby-dir': 'desc',});
                }
              }}
            />
          </Col>
        <Col padded xl={6} sm={6} xs><Select options={orderTypes}
          value={order_search_filers.order_type}
          onChange={_createEventHandler(onUpdate, 'order_type', {type: 'select', additionalPayload:{'start-index': 0}})} />
        </Col>
        <Col padded xl={6} sm={6} xs style={{alignSelf: 'center',}}>
          <LabeledCheckbox label="Show Deleted" checked={order_search_filers.show_deleted || false} onChange={(e) => {
            onUpdate({show_deleted: !(order_search_filers.show_deleted || false), 'start-index': 0,});
          }} />
        </Col>
      </Row>
      <Row>
        <Table style={{width: '100%',}}>
          <THead>
            <TR>
              <THSorted hideIconOnMobile width="26%" padded clickable order={getCurrentOrder('job_number')} onClick={() => {
                onUpdate({'orderby-field': 'job_number', 'orderby-dir': alternateOrder()});
              }}>Project #</THSorted>
              <THSorted hideIconOnMobile width="34%" padded clickable order={getCurrentOrder('job_name')} onClick={() => {
                onUpdate({'orderby-field': 'job_name', 'orderby-dir': alternateOrder()});
              }}>Name</THSorted>
              <THSorted hideIconOnMobile padded clickable order={getCurrentOrder('order_type')} onClick={() => {
                onUpdate({'orderby-field': 'order_type', 'orderby-dir': alternateOrder()});
              }}>Stage</THSorted>
              <THSorted hideIconOnMobile width="20%" padded clickable order={getCurrentOrder('date_orderdate')} onClick={() => {
                onUpdate({'orderby-field': 'date_orderdate', 'orderby-dir': alternateOrder()});
              }}>Date</THSorted>
            </TR>
          </THead>
          <TBody>
            {orders.map((v, i) => {
              return (
                <TR key={i}>
                  <TD padded><strong>{v.job_number} {v.active != 1 ? <Badge color={'cta'}>Deleted</Badge> : ''}</strong></TD>
                  <TD width="35%" padded><a target="_blank" rel="noopener noreferrer" href={'/project/' + v.job_number}>{v.job_name}</a></TD>
                  <TD padded>{ORDER_TYPE_MAP[v.order_type]}</TD>
                  <TD padded>{v.date_orderdate ? v.date_orderdate.split(' ')[0] : ''}</TD>
                </TR>
              );
            })}
          </TBody>
        </Table>
      </Row>
      <Pagination
        data={orders}
        start_index={order_search_filers['start-index']}
        max_results={order_search_filers['max-results']}
        onUpdate={(data) => {
          onUpdate({...data});
        }} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let orders = Object.values(state.entities.orders);
  if (state.dropdowns.orders && state.dropdowns.orders[ownProps.client_id]) {
    orders = (state.dropdowns.orders[ownProps.client_id] || []).map(o => state.entities.orders[o]);
  }

  return {
    identity: state.identity,
    user_id: state.identity.user_id,
    orders: orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadOrders: (
      client_id,
      options={
        'max-results': 4294967296,
        search: '',
        ignore_id: '',
      }
    ) => dispatch(createLoadOrderListV2(client_id, options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProjects);
