import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Artwork, Dropzoned } from '@commonsku/styles';
import { createSearchFiles, createUpdateFileV2, createUploadFile, createDeleteFile } from '../../actions/file';
import { getImageSrc, _createEventHandler } from '../../utils';
import { getArtworkFolders, getArtworks } from './selectors';
import { Select } from '../helpers';

export function Artworks({
  parent_type='CLIENT',
  parent_id,
  artworks,
  folders,
  loadArtworks,
  uploadFile,
  updateFile,
  deleteFile,
  ...props
}) {
  const [artwork_search_filers, setArtworkSearchFilter] = useState({folder_id: '',});
  const onUpdate = (data) => setArtworkSearchFilter({...artwork_search_filers, ...data });

  const editing_files = artworks.filter(v => v.edit === true); // any files being editted (open) currently

  useEffect(() => {
    loadArtworks(artwork_search_filers);
  }, []);

  function createEventHandler (data, action) {
    if (action === 'delete') {
      return () => {
        if (editing_files.length) {
          editing_files.forEach(v => {
            updateFile({ ...v, update_remote: true, additionalPayload:{edit: false} });
          });
        }
        return deleteFile(data);
      };
    }
    else if (action === 'edit') {
      return () => {
        if (editing_files.length) {
          editing_files.forEach(v => {
            updateFile({ ...v, update_remote: true, additionalPayload:{edit: false} });
          });
        }
        return updateFile({ ...data, edit: true, update_remote: false });
      };
    }
    else if (action === 'save') {
      return () => updateFile({ ...data, update_remote: true, additionalPayload:{edit: false} });
    }

    else if (action === 'change') {
      return (e) => updateFile({ ...data, file_display_name: e.target.value, update_remote: false });
    }
    else if (action === 'blur') {
      return (e) => updateFile({ ...data, file_display_name: e.target.value, update_remote: true });
    }
  }

  return (<div>
    <Row>
      <Col xs style={{zIndex: 100}}>
        <Select
          options={folders}
          value={artwork_search_filers.folder_id}
          onChange={_createEventHandler(onUpdate, 'folder_id', {type: 'select'})}/>
      </Col>
    </Row>
    <Row padded>
      {props.access !== false ?
      <Dropzoned style={{width: '100%'}}
        placeholder="Upload Files"
        onDrop={(acceptedFiles) => {
          acceptedFiles.forEach(f => {
            uploadFile(f, artwork_search_filers.folder_id || null);
          });
          onUpdate({});
        }}
      /> : null}
    </Row>
    <Row>
      {artworks.map((v, i) => {
        if (!artwork_search_filers.folder_id || artwork_search_filers.folder_id == v.folder_id) {
          return (
            <Col xl={6} lg={6} sm={6} xs padded key={i}>
              <Artwork name={v.file_display_name}
                picture={getImageSrc(v, 'medium')}
                edit={v.edit}
                onEdit={createEventHandler(v, 'edit')}
                onDelete={createEventHandler(v.file_id, 'delete')}
                onSave={createEventHandler(v, 'save')}
                inputProps={{
                  onChange: createEventHandler(v, 'change'),
                  onBlur: createEventHandler(v, 'blur'),
                }} />
            </Col>
          );
        }
      })}
    </Row>
  </div>);
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    user_id: state.identity.user_id,
    artworks: getArtworks(state),
    folders: getArtworkFolders(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadArtworks: (search={}) =>
      dispatch(createSearchFiles(ownProps.parent_id, ownProps.parent_type, search)),
    uploadFile: (file_blob, folder_id = null) => {
      try {
        dispatch(createUploadFile(ownProps.parent_id, ownProps.parent_type, file_blob, folder_id));
      } catch (error) {
        console.log('UPLOAD ERROR: ', error);
      }
    },
    updateFile: (file) =>
      dispatch(createUpdateFileV2(file)),
    deleteFile: (file_id) =>
      dispatch(createDeleteFile(file_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Artworks);
