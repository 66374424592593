import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Button, Popup, PopupHeader } from '@commonsku/styles';
import { closePopup } from '../../../actions/popup';
import { updateOrder } from '../../../actions/order';
import { createAddContact } from '../../../actions/contact';
import CreateContactForm from '../../contact/CreateContactForm';

export function CreateContactPopup({
    parent_id,
    parent_type='CLIENT',
    onClosePopup,
    onCreate,
    ...props
}) {
    const [form, setForm] = useState({});
    const [isFormValid, setFormValid] = React.useState(true);
    const [showErrorHighlight, setShowErrorHighlight] = React.useState(false);
    const node = React.useRef();

    const scrollToTop = () => {
        if (node && node.current) {
            node.current.scrollIntoView({ behavior: 'smooth', block: 'start', });
        }
    };

    const handleSubmit = () => {
        if (!isFormValid) {
            setShowErrorHighlight(true);
            scrollToTop();
            return false;
        }
        onCreate(form);
        onClosePopup();
    };

    return (
        <Popup
            xsStyle="height: 90%; padding-bottom: 50px;"
            mdStyle="max-width: 700px;"
            onClose={() => onClosePopup()}
            header={
                <PopupHeader xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                    <Col xs sm={5} style={{textAlign: 'left', alignSelf: 'center'}}>
                        <span className="title">Add Contact</span>
                    </Col>
                    <Col xs sm={7} style={{textAlign: 'right', alignSelf: 'center'}}>
                        <Button style={{ marginRight: 5 }} onClick={onClosePopup}>Close</Button>
                        <Button cta onClick={handleSubmit}>Create</Button>
                    </Col>
                </PopupHeader>
            }
        >
            <CreateContactForm
                ref={node}
                parent_id={parent_id}
                parent_type={parent_type}
                showError={showErrorHighlight}
                setFormValid={setFormValid}
                setForm={setForm}
            />
        </Popup>
    );
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
    // onClosePopup: () => dispatch(closePopup()),
    onCreate: contact => {
        contact = {...contact,};
        if (contact.contact_tags && contact.contact_tags.length > 0) {
            contact.contact_tags = contact.contact_tags.join(',');
        } else {
            contact.contact_tags = '';
        }
        dispatch(createAddContact(contact)).then(action => {
            dispatch(closePopup());
            const orderId = ownProps.order_id;
            if (!orderId) { return; }
            dispatch(updateOrder(
                orderId,
                ownProps.field,
                ownProps.previous_value,
                action.payload.contact.contact_id
            ));
        });
    },
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(CreateContactPopup);
export default ConnectedComponent;
