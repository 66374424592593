import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Popup, PopupHeader, Table, TBody, TD, TH, THead, TR, H3, Label, H1 } from '@commonsku/styles';
import { oauth } from '../../../utils';

const ERRORS = {
  'INVALID-FIELD': ({field}) => `Invalid value specified for column '${field}'`,
  'CLIENT-DATA-MISMATCH': ({clientName, firstRow, column}) => `Column '${column}' for client '${clientName}' does not match its first appearance on row ${firstRow}. This column must be the same for all contacts in this client.`,
  'CONTACT-EXISTS': ({contactName}) => `Contact with name ${contactName} already exists`,
  'ADDRESS-EXISTS': ({addressName}) => `Address with name ${addressName} already exists`,
  'INVALID-STATE': ({state, country}) => `${state} is not a valid ${country === 'Canada' ? 'province' : 'state'} in ${country}`,
  'BLANK-FIELD': ({field}) => `Column '${field}' cannot be blank`,
};

const WARNINGS = {
  'DEFAULT-FIELD': ({field}) => `Default value used for column '${field}'`,
  'DEFAULTS': () => "Default values were used for this client (marked with a '*' in the panel above)",
};

const translateError = (error) => {
  const key = error.error;
  if (!(key in ERRORS)) {
    return 'Unknown error';
  }
  return ERRORS[key](error);
};

const translateWarning = (warning) => {
  const key = warning.warning;
  if (!(key in WARNINGS)) {
    return 'Unknown warning';
  }
  return WARNINGS[key](warning);
};

function ClientWarnings({client}) {
  const hasDefaultWarnings = client.warnings
    .some(warning => warning.warning === 'DEFAULT-FIELD');

  const warnings = client.warnings
    .filter(warning => warning.warning !== 'DEFAULT-FIELD');

  if (hasDefaultWarnings) {
    warnings.push({warning: 'DEFAULTS'});
  }

  return <>
    <H3 style={{fontSize: '20px', marginBottom: '1rem'}} className="title">
      Warnings
    </H3>
    <Table>
      <TBody style={{border: 'none'}}>
        {warnings.map((warning, index) => (
          <TR key={index}>
            <TD style={{padding: '8px'}} bg='#FFF9C5' colSpan='1'>
                <div style={{fontFamily: 'skufont-regular', fontSize: '16px', color: '#C69B00'}}>{translateWarning(warning)}</div>
            </TD>
          </TR>
        ))}
      </TBody>
    </Table>
  </>;
}

function ClientInfo({client}) {
  const industries = useSelector(state => state.entities.industries);
  const taxes = useSelector(state => state.dropdowns.taxes)
    .reduce((map, tax) => { map[tax.tax_id] = tax; return map; }, {});
  const terms = useSelector(state => state.dropdowns.terms)
    .reduce((map, terms) => { map[terms.terms_id] = terms; return map; }, {});
  const users = useSelector(state => state.dropdowns.users)
    .reduce((map, user) => { map[user.user_id] = user; return map; }, {});
  const statuses = useSelector(state => state.dropdowns.account_statuses)
    .reduce((map, status) => { map[status.account_status_id] = status; return map; }, {});

  const fontStyles = {
    fontFamily: 'skufont-regular',
    color: '#000000',
  };

  const industry = client.industry_id in industries
    ? industries[client.industry_id].industry_name
    : (!client.industry_id ? '(unspecified)' : '(invalid)');

  const tax = client.default_tax_id in taxes
    ? taxes[client.default_tax_id].label
    : (!client.default_tax_id ? '(unspecified)' : '(invalid)');

  const term = client.default_terms_id in terms
    ? terms[client.default_terms_id].terms_name
    : (!client.default_terms_id ? '(unspecified)' : '(invalid)');

  const rep = client.sales_rep_id in users
    ? users[client.sales_rep_id]
    : null;

  const status = client.account_status_id in statuses
    ? statuses[client.account_status_id].account_status_name
    : '(unspecified)';

  const account_number = (
   'client_tenant_account_number' in client &&
    client.client_tenant_account_number.length > 0
  ) ? client.client_tenant_account_number
    : '(unspecified)';

  const phones = client.phones.length > 0
    ? client.phones
      .map(phone => phone['client.phone_number'])
      .reduce((phones, nextPhone) => <>{phones}<br />{nextPhone}</>)
    : '(unspecified)';

  const tags = 'tags' in client
    ? client.tags
    : '(unspecified)';

  const defaults = client.warnings
    .filter(warning => warning.warning === 'DEFAULT-FIELD')
    .map(warning => warning.field);

  return <div style={{width: '100%', overflowWrap: 'break-word'}}>
    <Row style={{marginBottom: '1rem'}}>
    <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Sales Rep {defaults.includes('Sales Rep Email') && '(*)'}</Label>
        {rep
          ? <div style={fontStyles}>
            <div style={fontStyles}>{rep.contact_first_name} {rep.contact_last_name}</div>
            <div style={fontStyles}>{rep.contact_email}</div>
          </div>
          : <div style={fontStyles}>{!client.sales_rep_id ? '(unspecified)' : '(invalid)'}</div>
        }
      </Col>
      <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Account Number</Label>
        <div style={fontStyles}>{account_number}</div>
      </Col>
      <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Phone Numbers</Label>
        <div style={fontStyles}>{phones}</div>
      </Col>
    </Row>
    <Row style={{marginBottom: '1rem'}}>
      <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Tax {defaults.includes('Tax Name') && '(*)'}</Label>
        <div style={fontStyles}>{tax}</div>
      </Col>
      <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Industry {defaults.includes('Industry') && '(*)'}</Label>
        <div style={fontStyles}>{industry}</div>
      </Col>
      <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Tags</Label>
        <div style={fontStyles}>{tags}</div>
      </Col>
    </Row>
    <Row style={{marginBottom: '1rem'}}>
      <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Account Status</Label>
        <div style={fontStyles}>{status}</div>
      </Col>
      <Col xs={4}>
        <Label as="div" style={{color: '#000000'}}>Default Terms {defaults.includes('Default Terms') && '(*)'}</Label>
        <div style={fontStyles}>{term}</div>
      </Col>
    </Row>
  </div>;
}

function ClientLabel({client, activeClient, setActiveClient}) {
  const errorCount = client.error_rows.length;
  const inactiveStyles = {
    color: '#00B1C8',
    background: '#E1F7FA',
    border: '2px solid #E1F7FA',
  };
  const activeStyles = {
    color: '#00B1C8',
    background: '#FFFFFF',
    border: '2px solid #00B1C8',
    fontWeight: 700,
  };

  return <Row>
    <div
      style={{fontFamily: 'skufont-regular', display: 'block', width: '100%', padding: '16px', borderRadius: '8px', cursor: 'pointer', ...(client === activeClient ? activeStyles : inactiveStyles)}}
      onClick={() => {setActiveClient(client);}}
      key={client.name}
    >
      <div style={{float: 'left'}}>
        {client.name}
      </div>
      <div style={{float: 'right'}}>
        {errorCount > 0 && (
          <span style={{color: '#E52633', marginRight: '1rem'}}>
            {errorCount} error(s)
          </span>
        )}
        <span style={{color: '#00B1C8'}}>
          {client.contacts.length} contacts
        </span>
      </div>
    </div>
  </Row>;
}

function ClientPanel({client}) {
  return <>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '16px', gap: '16px', marginBottom: '1rem', background: '#EDF4F7'}}>
      <ClientInfo client={client} />
    </div>
    {client.warnings.length > 0 &&
      <div style={{marginBottom: '1rem'}}>
        <ClientWarnings client={client} />
      </div>
    }
    {client.error_rows.length > 0 &&
      <div style={{marginBottom: '1rem'}}>
        <ErrorTable client={client} />
      </div>
    }
    {client.addresses.length > 0 &&
      <div style={{marginBottom: '1rem'}}>
        <AddressTable client={client} />
      </div>
    }
    {client.contacts.length > 0 &&
      <div style={{marginBottom: '1rem'}}>
        <ContactTable client={client} />
      </div>
    }
  </>;
}

function ErrorTable({client}) {
  return <>
    <H3 style={{fontSize: '20px', marginBottom: '1rem'}} className="title">
      Errors
    </H3>
    <Table>
      <THead>
        <TR>
          <TH style={{padding: '8px'}} bg='#FDCACD' colSpan='1'>Row</TH>
          <TH style={{padding: '8px'}} bg='#FDCACD' colSpan='5'>Errors</TH>
        </TR>
      </THead>
      <TBody style={{border: 'none'}}>
        {client.error_rows.map((row, index) => (
          <TR key={index}>
            <TD style={{padding: '8px', fontSize: '16px', color: '#D10411'}} bg='#FFF2F3' colSpan='1'>{row.row_number}</TD>
            <TD style={{padding: '8px'}} bg='#FFF2F3' colSpan='5'>
                {row.errors.map((error) =>
                  <div style={{fontFamily: 'skufont-regular', fontSize: '16px', color: '#D10411'}}>{translateError(error)}</div>
                )}
            </TD>
          </TR>
        ))}
      </TBody>
    </Table>
  </>;
}

function AddressTable({client}) {
  return <>
    <H3 style={{fontSize: '20px', marginBottom: '1rem'}} className='title'>
      Addresses
    </H3>
    <Table>
      <THead>
        <TR>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='1'>Row</TH>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='2'>Name</TH>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='2'>Line 1</TH>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='2'>Line 2</TH>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='2'>City</TH>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='2'>State</TH>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='2'>Postal</TH>
          <TH style={{padding: '8px'}} bg='#DAE9EE' colSpan='2'>Country</TH>
        </TR>
      </THead>
      <TBody style={{border: 'none'}}>
        {client.addresses.flatMap((address, index) => [
          <TR key={index}>
            <TD style={{padding: '8px'}} bg='white' colSpan='1'>{address.row_number}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{address.address_name}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{address.address_line_1}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{address.address_line_2}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{address.address_city}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{address.address_state}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{address.address_postal}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{address.address_country}</TD>
          </TR>,
          address.warnings.map((warning, warningIndex) => (
            <TR key={index + '.' + warningIndex}>
              <TD style={{padding: '8px'}} bg='#FFF9C5' colSpan='15'>
                <div style={{fontSamily: 'skufont-regular', fontSize: '16px', color: '#C69B00'}}>{translateWarning(warning)}</div>
              </TD>
            </TR>
          ))
        ])}
      </TBody>
    </Table>
  </>;
}

function ContactTable({client}) {
  const departments = useSelector(state => state.entities.departments);

  return <>
    <H3 style={{fontSize: '20px', marginBottom: '1rem'}} className="title">
      Contacts
    </H3>
    <Table>
      <THead>
        <TR>
          <TH style={{padding: '8px'}} bg='DAE9EE' colSpan='1'>Row</TH>
          <TH style={{padding: '8px'}} bg='DAE9EE' colSpan='2'>First Name</TH>
          <TH style={{padding: '8px'}} bg='DAE9EE' colSpan='2'>Last Name</TH>
          <TH style={{padding: '8px'}} bg='DAE9EE' colSpan='3'>Contact Email</TH>
          <TH style={{padding: '8px'}} bg='DAE9EE' colSpan='3'>Address Name</TH>
          <TH style={{padding: '8px'}} bg='DAE9EE' colSpan='2'>Department</TH>
        </TR>
      </THead>
      <TBody style={{border: 'none'}}>
        {client.contacts.flatMap((contact, index) => [
          <TR key={index}>
            <TD style={{padding: '8px'}} bg='white' colSpan='1'>{contact.row_number}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{contact.contact_first_name}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{contact.contact_last_name}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='3'>{contact.contact_email}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='3'>{contact.address_name}</TD>
            <TD style={{padding: '8px'}} bg='white' colSpan='2'>{departments[contact.department_id].department_name}</TD>
          </TR>,
          contact.warnings.map((warning, warningIndex) => (
            <TR key={index + '.' + warningIndex}>
              <TD style={{padding: '8px', borderRadius: '8px', color: '#C69B00'}} bg='#FFF9C5' colSpan='13'>
                {translateWarning(warning)}
              </TD>
            </TR>
          ))
        ])}
      </TBody>
    </Table>
  </>;
}

export default function ClientImportPreviewPopup({
  preview,
  previewImportId,
  onClosePopup,
}) {
    const [activeClient, setActiveClient] = useState(preview.clients[0]);
    const hasErrors = preview.clients.some(client => client.error_rows.length > 0);
    const onlyErrors = !preview.clients.some(client => client.error_rows.length === 0);

    const handleImport = async () => {
      const data = {
        import_type: 'CLIENT',
        bulk_import_id: previewImportId,
        preview: false,
      };

      oauth('POST', 'import', data);
      onClosePopup();
    };

    const onCancel = () => {
      const data = {
        import_type: 'CANCEL',
        bulk_import_id: previewImportId,
      };

      oauth('POST', 'import', data);
      onClosePopup();
    };

    return (
      <Popup
        style={{height: '100%', minHeight: '200px', maxHeight: 'calc(100% - 64px)', width: 'calc(100% - 64px)', padding: '32px'}}
        header={
          <PopupHeader style={{padding: 0, marginBottom: '1rem'}}>
            <H1 style={{margin: '0px'}} className='title'>
              Preview Import
            </H1>
          </PopupHeader>
        }
        onClose={onClosePopup}
      >
        {hasErrors && (
          <Row style={{padding: '16px', gap: '10px', alignItems: 'center', background: '#FFF2F3', borderRadius: '8px', height: '91px', marginBottom: '1rem'}}>
            <svg width="44" height="39" viewBox="0 0 44 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 38.5H44L22 0.5L0 38.5ZM24 32.5H20V28.5H24V32.5ZM24 24.5H20V16.5H24V24.5Z" fill="#B4000C"/>
            </svg>
            <div style={{display: 'block'}}>
              <H3 as="div" style={{fontSize: '20px', color: '#B4000C'}}>
                There are errors in some of your clients
              </H3>
              <div style={{fontFamily: 'skufont-regular', fontSize: '18px', color: '#123952'}}>
                {onlyErrors
                  ? "Fix the errors in the CSV file and retry the import."
                  : "You can import the clients and contacts that don't have errors, or cancel, fix the errors in the CSV file, and retry the import."
                }
              </div>
            </div>
          </Row>
        )}
        <Row style={{height: 'calc(100% - 54px' + (hasErrors ? ' - 91px)' : ')'), marginBottom: '1rem'}}>
          <Col xs={4} style={{display: 'flex', flexDirection: 'column', gap: '12px', paddingRight: '32px', overflowY: 'scroll', height: '100%'}}>
            {preview.clients.map((client, index) =>
              <ClientLabel
                key={index}
                client={client}
                activeClient={activeClient}
                setActiveClient={setActiveClient}
              />
            )}
          </Col>
          <Col xs={8} style={{overflowY: 'scroll', height: '100%'}}>
            <ClientPanel client={activeClient} />
          </Col>
        </Row>
        <Row style={{position: 'fixed', right: '32px'}}>
          <Button secondary style={{width: '150px', marginLeft: 'auto', marginRight: '1rem'}} onClick={onCancel}>Cancel</Button>
          <Button cta style={{width: '327px'}} disabled={onlyErrors} onClick={handleImport}>Import</Button>
        </Row>
      </Popup>
    );
}
