import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from '@commonsku/styles';

import { find, map, orderBy } from 'lodash';
import { useAvalara } from './hooks';

const AvalaraEntityUseCodeSelect = ({ value, onChange, ...props }) => {

  const avalara = useAvalara();
  const [entityUseCodes, setEntityUseCodes] = useState([]);
  useEffect(() => {
    avalara.getAvalaraEntityUseCodes().then(({ json }) => {
      setEntityUseCodes(json.entity_use_codes);
    });
  });

  const options = map(orderBy(entityUseCodes, 'code'), ({
    code, description, name
  }) => {
    return {
      value: `${code} ${description} ${name}`,
      code,
      label: <div>
        <div style={{ fontWeight: 'bold ' }}>{code} - {name}</div>
        <div style={{ fontSize: '0.8rem' }} className="avalara-entity-use-code-description">
          {description}
        </div>
      </div>
    };
  });

  return <Select
    value={find(options, { code: value })}
    options={options}
    onChange={(option) => {
      return onChange(option.code);
    }}
    {...props}
  />;
};

export default styled(AvalaraEntityUseCodeSelect)`
  .commonsku-styles-select__value-container .avalara-entity-use-code-description {
    display: none;
  }
`;