import _ from 'lodash';
import { countries, states } from '../../geo';

export const reports_options = [
    {label: 'Invalid Emails', value: 'invalid_emails'},
    {label: 'Duplicate Emails', value: 'duplicate_emails'},
];

export const states_options = [{label: 'All', value: 'all'}];
_.map(_.values(states), v => {
  _.map(v, s => {
    states_options.push({label: s.name, value: s.abbr});
  });
});

export const default_opts = [{label: 'All', value: 'all'}];

export const countries_options = default_opts.concat(_.map(countries, v => ({
  label: v, value: v
})));

export const contact_status_options = [
  {label: 'Any', value: ''},
  {label: 'Active', value: '1'},
  {label: 'Inactive', value: '0'},
];

export const yes_no_options = [
  {label: 'Any', value: ''},
  {label: 'Yes', value: '0'},
  {label: 'No', value: '1'},
];

export const default_prefs = {
  client_rep: ['all'],
  client: ['all'],
  client_tag: ['all'],
  client_status: ['all'],
  industry: ['all'],
  department: ['all'],
  country: ['all'],
  state: ['all'],
  contact_status: '1',
  no_marketing: '0',
};

function isValidDate(date) {
  return _.isDate(date);
}

export const formatDateTimeStr = (d=null, def='') => {
  if (!d) { return def; }
  let dt = d;
  if (!isValidDate(d)) {
    dt = new Date(d);
  }

  return dt.toDateString().split(' ').slice(1).join(' ') + ' ' + dt.toLocaleTimeString();
};

export const getContactMP = (c) => {
  const result = {mp: null, last_synced: null, sync_status: null};

  if (!c) { return result; }

  if (c.mailchimp_last_synced && !c.hubspot_last_synced) {
    result['mp'] = 'Mailchimp';
    result['last_synced'] = c.mailchimp_last_synced;
    result['sync_status'] = c.mailchimp_sync_status;
  } else if (!c.mailchimp_last_synced && c.hubspot_last_synced) {
    result['mp'] = 'Hubspot';
    result['last_synced'] = c.hubspot_last_synced;
    result['sync_status'] = c.hubspot_sync_status;
  } else if (c.mailchimp_last_synced && c.hubspot_last_synced) {
    if (c.mailchimp_last_synced < c.hubspot_last_synced) {
      result['mp'] = 'Hubspot';
      result['last_synced'] = c.hubspot_last_synced;
      result['sync_status'] = c.hubspot_sync_status;
    } else {
      result['mp'] = 'Mailchimp';
      result['last_synced'] = c.mailchimp_last_synced;
      result['sync_status'] = c.mailchimp_sync_status;
    }
  }
  if (result.last_synced) {
    result.last_synced = new Date(result.last_synced);
  }
  return result;
};

export function transformHubspotCampaign(campaign, fromMarketingEmails=false) {
  if (!campaign) { return null; }

  return {
    ...campaign,
    campaignName: _.get(campaign, 'name', ''),
    send_time: _.get(campaign, 'publishedAt', ''),
    campaignStats: {
      'Opens': {
        value: (fromMarketingEmails ? campaign.stats : campaign).counters.open
      },
      'Clicks': {
        value: (fromMarketingEmails ? campaign.stats : campaign).counters.click
      },
      'Sent': {
        value: (fromMarketingEmails ? campaign.stats : campaign).counters.sent
      },
      'Unsubscribed': {
        value: fromMarketingEmails
          ? campaign.stats.ratios.spamreportratio
          : '',
        colSize: 4,
      },
      'Delivered': {
        value: fromMarketingEmails
          ? ''
          : campaign.counters.delivered,
        colSize: 4,
      },
    }
  };
}

export const HS_EMAIL_EVENTS_STATUSES = {
  'SENT': 'Sent',
  'DROPPED': 'Dropped',
  'PROCESSED': 'Processed',
  'DELIVERED': 'Delivered',
  'DEFERRED': 'Deferred',
  'BOUNCE': 'Bounce',
  'OPEN': 'Open',
  'CLICK': 'Click',
  'STATUSdeliveredCHANGE': 'Status Change',
  'SPAMREPORT': 'Spam Report',
};

export function transformHubspotContactActivity({campaigns, activity, campaignId}) {
  if (activity && activity.events) {
    const groups = {};
    const contactsCampaignStats = {};

    activity.events.forEach((e, i) => {
      if (campaignId && campaignId != e.emailCampaignId) {
        return null;
      }

      const result = {};
      if (!groups[e.emailCampaignId]) {
        groups[e.emailCampaignId] = [];
      }

      if (campaigns) {
        const fromMarketingEmails = _.has(_.first(_.values(campaigns)), 'allEmailCampaignIds', false);
        const campaign_id = parseInt(campaignId || e.emailCampaignId);
        result['campaign'] = !fromMarketingEmails ? _.get(campaigns, campaign_id, {}) :  _.find(
          _.values(campaigns),
          v => v.allEmailCampaignIds
            ? v.allEmailCampaignIds.includes(campaign_id)
            : e.id == campaign_id
        );
        if (result['campaign']) {
          result['campaign'] = transformHubspotCampaign(result['campaign'], fromMarketingEmails);
          result['action'] = HS_EMAIL_EVENTS_STATUSES[e.type] || _.upperFirst(_.lowerCase(e.type));
          result['campaignName'] = result['campaign'].name;
        }
      }

      if (!contactsCampaignStats[e.emailCampaignId]) {
        contactsCampaignStats[e.emailCampaignId] = {
          'Opens': 0,
          'Clicks': 0,
        };
      }

      if (e.type == 'OPEN') {
        contactsCampaignStats[e.emailCampaignId].Opens += 1;
      } else if (e.type == 'CLICK') {
        contactsCampaignStats[e.emailCampaignId].Clicks += 1;
      }

      result['actionDate'] = new Date(e.created);
      result['actionDateStr'] = '';
      if (result['actionDate']) {
        result['actionDateStr'] = formatDateTimeStr(result['actionDate']);
      }
      groups[e.emailCampaignId].push(result);
    });

    Object.keys(contactsCampaignStats).forEach(k => {
      if (groups[k]) {
        groups[k] = groups[k].map(v => {
          return {...v, contactCampaignStats: contactsCampaignStats[k]};
        });
      }
    });

    return groups;
  }
  return [];
}

export function transformMailchimpCampaign(campaign) {
  if (!campaign) { return null; }

  let campaignName = _.get(campaign, 'settings.title', _.get(campaign, 'settings.subject_line', ''));
  if (_.get(campaign, 'is_variate', false)) {
    campaignName += ' (Variate)';
  }

  return {
    ...campaign,
    campaignName: campaignName,
    campaignType: _.get(campaign, 'type', ''),
    campaignStats: {
      'Opens': {
        value: _.get(campaign, 'report_summary.opens', '')
      },
      'Open Rate': {
        value: _.get(campaign, 'report_summary.open_rate', ''),
        isPercentage: true,
      },
      'Clicks': {
        value: _.get(campaign, 'report_summary.clicks', '')
      },
      'Click Rate': {
        value: _.get(campaign, 'report_summary.click_rate', ''),
        isPercentage: true,
      },
      'Unique Opens': {
        value: _.get(campaign, 'report_summary.unique_opens', ''),
        colSize: 6,
      },
      'Subscriber Clicks': {
        value: _.get(campaign, 'report_summary.subscriber_clicks', ''),
        colSize: 6,
      },
    }
  };
}

export function transformMailchimpContactActivity({campaigns, activity, campaignId}) {
  const results = {};
  const contactsCampaignStats = {};
  _.forEach(activity.activity, v => {
    if (campaignId && v.campaign_id != campaignId) {
      return null;
    }

    const campaign = campaignId ? campaigns[campaignId] : campaigns[v.campaign_id];
    if (!campaign) { return null; }

    if (!results[campaign.id]) {
      results[campaign.id] = [];
    }
    const result = {...v};
    result['campaignType'] = v.type || '';
    result['campaignName'] = v.title || (_.get(campaign, 'settings.subject_line', ''));
    result['isOpen'] = result['action'] == 'open';
    result['isClick'] = result['action'] == 'click';
    result['isUnsub'] = result['action'] == 'unsub';
    result['isEcomm'] = result['action'] == 'ecomm';

    if (!contactsCampaignStats[campaign.id]) {
      contactsCampaignStats[campaign.id] = {
        'Opens': 0,
        'Clicks': 0,
        'Unsubscribed': 0,
      };
    }

    if (result['isOpen']) {
      contactsCampaignStats[campaign.id].Opens += 1;
    } else if (result['isClick']) {
      contactsCampaignStats[campaign.id].Clicks += 1;
    } else if (result['isUnsub']) {
      contactsCampaignStats[campaign.id].Unsubscribed += 1;
    }
    if (result['action'] == 'unsub') {
      result['action'] = 'Unsubscribe';
    } else if (result['action'] == 'ecomm') {
      result['action'] = 'E-Commerce';
    } else {
      result['action'] = _.upperFirst(_.lowerCase(result['action'] || ''));
    }
    if (v.campaign_id) {
      if (!result['campaignName']) {
        result['campaignName'] = _.get(campaign, 'settings.subject_line', '');
      }
    }

    result['campaign'] = campaign;
    result['campaign'] = transformMailchimpCampaign(result['campaign']);
    result['campaignType'] = _.upperFirst(_.lowerCase(result['campaignType']));
    result['actionDate'] = new Date(Date.parse(v.timestamp));
    result['actionDateStr'] = '';
    if (result['actionDate']) {
      result['actionDateStr'] = formatDateTimeStr(result['actionDate']);
    }
    results[campaign.id].push(result);
  });

  Object.keys(contactsCampaignStats).forEach(k => {
    if (results[k]) {
      results[k] = results[k].map(v => {
        return {...v, contactCampaignStats: contactsCampaignStats[k]};
      });
    }
  });

  return results;
}

export function parseMailchimpCampaigns(campaigns) {
  const results = [];
  campaigns.forEach(v => {
    const campaign = {...v,
      name: v.settings.title || v.settings.subject_line,
    };
    results.push(campaign);

    // check for  multivariate settings
    if (v.variate_settings) {
      let has_win_c_id = false;
      const winning_campaign_id = v.variate_settings.winning_campaign_id;

      // add variate combinations
      v.variate_settings.combinations.forEach(c => {
        if (c.id == winning_campaign_id) {
          has_win_c_id = true;
        }

        results.push({...campaign,
          id: c.id,
          campaign_id: c.id,
          send_time: v.variate_settings.send_times[c.send_time],
          subject_line: v.variate_settings.subject_lines[c.subject_line],
          title: v.variate_settings.subject_lines[c.subject_line],
          is_variate: true,
        });
      });

      // check if winning variate campaign is not in combination, then add
      if (!has_win_c_id) {
        results.push({...campaign,
          id: v.variate_settings.winning_campaign_id,
          campaign_id: v.variate_settings.winning_campaign_id,
          is_variate: true,
        });
      }
    }
  });

  return results;
}