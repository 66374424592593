import React from 'react';
import {
  LabeledProgress, SizerWrapper, Spinner, Number, colors,
  ResponsiveTable, THead, TBody, TR, TH, TD, THSorted,
} from '@commonsku/styles';

const COL_SIZES = {
  col1: '25%',
  col2: '20%',
  col3: '25%',
  col4: 'auto',
};

function TableRow({
  client_id,
  client_name,
  latest_use,
  company_type,
  primary_contact_first_name,
  primary_contact_last_name,
  primary_contact_email,
  primary_contact_phone_number,
  primary_contact_phone_extension,
  onClickRow,
  hasAccess,
  isSelected=false,
  isAdmin,
  identity,
  ...props
}) {
  const _hasAccess = hasAccess(props.client_rep_user_id);
  const rep_name = (props.client_rep_first_name || '') + ' ' + (props.client_rep_last_name || '');

  const _sales_target = props.sales_target && !isNaN(props.sales_target) ? parseFloat(props.sales_target).toFixed(0) : 0;
  const y1_total = props.y1_total && !isNaN(props.y1_total) ? parseFloat(props.y1_total) : 0;

  return (
    <TR selected={isSelected} onClick={(e) => { e.stopPropagation(); onClickRow({ access: hasAccess(props.client_rep_user_id) }); }}>
      <TD clickable padded width={COL_SIZES.col1}>
        <div style={{
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          <strong>{client_name}</strong>
          {/* Only show this on screen size <= md */}
          <SizerWrapper xl={'display: none;'} sm={'display: table-cell;'}>
            Rep: {rep_name}
          </SizerWrapper>
        </div>
      </TD>

      {/* Hide this on screen size <= md */}
      <TD width={COL_SIZES.col2} clickable padded xl={'display: table-cell;'} sm={'display: none;'}>{rep_name}</TD>

      <TD clickable padded width={COL_SIZES.col3}>
      {!_hasAccess ? '' :
        <React.Fragment>
          {(props.sales_target && !isNaN(props.sales_target) && parseInt(props.sales_target) !== 0)
            ? <LabeledProgress min={0} max={_sales_target} value={y1_total} />
            : <span>
                <span style={{ fontWeight: "bold", color: "#00a259" }}>
                  ${y1_total
                    ? <Number commas decimalPoints={0} num={y1_total} />
                    : y1_total}
                </span> / <span style={{color: colors.primary, textDecoration: 'none'}} onClick={(e) => {
                            e.stopPropagation();
                            onClickRow({
                              // action: 'set-target',
                              // autofocusField: 'sales_target',
                              // tab_index: 3,
                              access: hasAccess(props.client_rep_user_id)
                            });
                          }}>No Target Set</span>
              </span>}
        </React.Fragment>}
      </TD>

      <TD clickable padded>
          <React.Fragment>
            <strong>{(primary_contact_first_name || '') + ' ' + (primary_contact_last_name || '')}</strong>
            {primary_contact_phone_number
              ? <React.Fragment><br />{primary_contact_phone_number}</React.Fragment>
              : null}
            {primary_contact_email
              ? <React.Fragment><br />{primary_contact_email}</React.Fragment>
              : null}
          </React.Fragment>
      </TD>
    </TR>
  );
}

export function ClientsTable({
  companies, search_filters, selected_client,
  onClickRow, hasAccess, onUpdate, onClosePanel,
  isAdmin, identity, ...props
}) {
  function alternateOrder(order = null) {
    order = order || search_filters['order-dir'] || 'desc';
    return order === 'desc' ? 'asc' : 'desc';
  }

  function getCurrentOrder(type) {
    if (search_filters['order-by'] === type) {
      return search_filters['order-dir'] === 'desc' ? 'desc' : 'asc';
    }

    return null;
  }

  return (
    <React.Fragment>
      <ResponsiveTable style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%',}}>
        <THead>
          <TR>
            <THSorted order={getCurrentOrder('client_name')} width={COL_SIZES.col1} clickable padded onClick={() => {
              onUpdate({'order-by': 'client_name', 'order-dir': alternateOrder()});
            }}>Client</THSorted>

            <THSorted width={COL_SIZES.col2} clickable padded
              sm={'display: none;'} xl={'display: table-cell;'}
              order={getCurrentOrder('client_rep_first_name')}
              onClick={() => {
                onUpdate({'order-by': 'client_rep_first_name', 'order-dir': alternateOrder()});}
              }>Rep</THSorted>{/* Hide this on screen size <= md */}

            <THSorted width={COL_SIZES.col3} clickable
              order={getCurrentOrder('sales_target')}
              onClick={() => {
                onUpdate({'order-by': 'sales_target', 'order-dir': alternateOrder()});}
              }>Sales Target</THSorted>

            <TH>Contact</TH>

            {/* <THSorted clickable padded
              order={getCurrentOrder('last_activity')}
              onClick={() => {
                onUpdate({'order-by': 'last_activity', 'order-dir': alternateOrder()})}
              }>Last Activity</THSorted> */}
          </TR>
        </THead>
        <TBody padded>
          {!(props.loading && props.loading.companies === true)
            && companies.map(v => (
              <TableRow key={v.client_id} {...v}
                        onClickRow={(data) =>
                          selected_client === v.client_id ? onClosePanel() : onClickRow(v.client_id, data)}
                        isSelected={selected_client === v.client_id}
                        identity={identity}
                        hasAccess={hasAccess}
                        isAdmin={isAdmin} />
            ))}
        </TBody>
      </ResponsiveTable>
      {props.loading && props.loading.companies === true
        && <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}><Spinner /></div>}
    </React.Fragment>
  );
}
